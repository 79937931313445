import gql from 'graphql-tag';

export const WatchDialogWatchContentMutation = gql`
	mutation WatchDialogWatchContentMutation($id: ID!) {
		watchContent(watchContentInput: { contentId: $id, currentUser: true }) {
			content {
				id
				currentUserIsWatching
			}
		}
	}
`;
